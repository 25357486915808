@keyframes scaling {
    0%,
    100% {
        transform: scale(0.2);
        background-color: #30FFB7;
    }
    40% {
        transform: scale(1);
        background-color: #307f8b;
    }
    50% {
        transform: scale(1);
        background-color: #20242c;
    }
}

.loading-dot {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    transform: scale(0);
    animation: scaling 1s ease-in-out infinite;
    display: inline-block;
    margin: 0px 10px 0px 10px;
}

.loading-dot:nth-child(0) {
    animation-delay: 0s;
}

.loading-dot:nth-child(1) {
    animation-delay: 0.2s;
}

.loading-dot:nth-child(2) {
    animation-delay: 0.4s;
}

.loading-dot:nth-child(3) {
    animation-delay: 0.6s;
}

.animate-zoom {
    animation: animatezoom 1.8s;
    text-align: center!important
}

.animate-rotate {
    animation: rotation 2s infinite linear;
    animation-delay: 1.8s;
}

.center {
    text-align: center!important
}

.avenir-medium {
    font-family: avenir-medium;
}

.avenir-oblique {
    font-family: avenir-oblique;
}

.avenir-roman {
    font-family: avenir-roman;
}

.extra-bold {
    font-weight: 900;
}

.mgm-bosco-dark-gray {
    color: #3C3C3B;
}

.mgm-bosco-dark-blue {
    color: #4280ae;
    margin-top: -3px;
}

.hide-first {
    animation: 1.8s fadeIn;
    animation-fill-mode: forwards;
    visibility: hidden;
}

.loader-wrapper>body {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.65);
    z-index: 1000;
}

@keyframes fadeIn {
    99% {
        visibility: hidden;
    }
    100% {
        visibility: visible;
    }
}

@keyframes animatezoom {
    from {
        transform: scale(0)
    }
    to {
        transform: scale(1)
    }
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

// @font-face {
//     font-family: avenir-medium;
//     src: url(Fonts/Avenir/AvenirLTStd-Medium.ttf);
// }
// @font-face {
//     font-family: avenir-oblique;
//     src: url(Fonts/Avenir/AvenirLTStd-Oblique.ttf);
// }
// @font-face {
//     font-family: avenir-roman;
//     src: url(Fonts/Avenir/AvenirLTStd-Roman.ttf);
// }